import './app.css'
import Alpine from 'alpinejs'

const API_URL = 'https://roboticalugo.com/send.php'

const FORM_DATA_INITIAL = {
  nombre: '',
  email: '',
  empresa: '',
  localidad: ''
}

function contactForm() {
  return {
    formData: FORM_DATA_INITIAL,
    formLoading: false,
    submitForm() {
      // console.log(JSON.stringify(this.formData))
      this.formLoading = true
      const formData = new URLSearchParams()
      Object.keys(this.formData).forEach((key) => {
        formData.append(key, this.formData[key])
      })
      window
        .fetch(API_URL, {
          method: 'POST',
          body: formData
        })
        .then(() => {
          window.alert('Formulario enviado con éxito!')
          Object.keys(FORM_DATA_INITIAL).forEach((key) => {
            this.formData[key] = ''
          })
          this.formLoading = false
        })
        .catch(() => {
          window.alert(
            'Ocurrió un error desconocido. Inténtelo de nuevo más tarde.'
          )
          this.formLoading = false
        })
    }
  }
}
window.contactForm = contactForm

window.Alpine = Alpine
Alpine.start()
